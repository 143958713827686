@import 'default-antd.css';

.ant-input,
.ant-input-affix-wrapper {
  padding: 8px 11px;
}

.ant-input-affix-wrapper .ant-input {
  border-radius: 2px;
}

.ant-input-number {
  width: 100%;
}

// Button
.ant-btn {
  svg {
    display: inline-block;
  }

  display: flex;
  align-items: center;
  // border-radius: 6px;
}

// Dropdown
.ant-dropdown-menu {
  // border-radius: 6px;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  padding: 8px 12px;
}

.ant-select-dropdown {
  // border-radius: 6px;
}

// Tooltip
.ant-tooltip-inner {
  border-radius: 4px;
}

// Select
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  // border-radius: 6px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  // height: 37px;
  // padding: 3px 11px;
}

// Picker (Datepicker)
.ant-picker {
  // padding: 6px 11px 7px;
  // border-radius: 6px;
}

// Pagination
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: flex;
  align-items: center;
  justify-content: center;
}

// Modal
.ant-modal-close:focus {
  border: 0;
  outline: none;
}

// Radio
.ant-checkbox-inner {
  border-radius: 4px;
}

// Tooltip
.ant-tooltip {
  max-width: 50%;
  background-color: black;
}
